<template>
  <header class="header" ref="header" v-scroll-menu>
    <div id="container" class="header__container container">
      <Logo
        parent-class="header__logo"
        :content="content.header_logo"
        v-if="content.header_logo"
      />
      <Navigation
        parent-class="header__navigation"
        :content="menu"
        v-if="menu"
      />
      <div class="header__contacts">
        <a
          class="header__phone"
          :href="'tel:' + content.header_phone_link"
          v-if="content.header_phone_link"
          v-on:click="phoneGoal()"
        >
          {{ content.header_phone }}
        </a>
        <div class="header__сallback" v-on:click="openPopup()">Заказать обратный звонок</div>
      </div>
      <Burger parent-class="header__burger" v-on:click="openMenu" />
      <MobileBar
        parent-class="header__mobile-bar"
        v-on:itemMenuClick="openMenu"
      />
    </div>
  </header>
</template>

<script>
import Logo from "@/components/element/LogoApp.vue";
import Navigation from "@/components/block/NavigationApp.vue";
import Burger from "@/components/element/BurgerApp.vue";
import MobileBar from "@/components/block/MobileBarApp.vue";

export default {
  components: {
    Logo,
    Navigation,
    MobileBar,
    Burger,
  },
  data() {
    return {
      content: this.$store.state.data.contentResponse.header,
      menu: this.$store.state.data.menuResponse,
      menuStatus: false,
    };
  },
  methods: {
    openMenu() {
      this.$refs.header.classList.toggle("mobile");
      this.menuStatus = !this.menuStatus;

      document.querySelector("body").style.overflow = this.menuStatus
        ? "hidden"
        : "";
    },
    phoneGoal() {
      if (this.content.header_phone_metka) {
        this.$metrika.reachGoal(this.content.header_phone_metka);
      }
    },
    openPopup() {
      if (this.metka) {
        this.$metrika.reachGoal(this.metka);
      }

      this.$store.commit("updateOrderPopup", {
        status: true,
        data: this.content.header_popup_callback,
        name: null,
      });
    },
  },
};
</script>

<style>
.header {
  position: fixed;
  z-index: 999;

  width: 100%;

  background-color: #ffffff;

  transform: translateY(0);
  transition: transform 0.3s ease-in, background-color 0.5s linear 0s;
}

.header.show {
  transform: translateY(-100%);
}

.header__container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 104px;
  padding-top: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid var(--backgraund-site-bg);

  transition: border-color 0.3s ease-in;
}

.header__logo {
  max-width: 300px;
}

.header__navigation {
  max-width: 1010px;
  margin-left: auto;
  margin-right: auto;
}

.header__contacts {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  text-align: right;
  max-width: 200px;
}

.header__phone {
  position: relative;

  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 150%;
  text-decoration: none;
  color: var(--main-menu-color-white-phone);

  transition: color 1s ease;
}

.header__phone:hover {
  color: var(--main-menu-color-white-phone-hover);
}

.header__phone::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: -2px;

  width: 0;
  height: 1px;

  background-color: var(--main-menu-color-white-phone-hover);
  transition: width 0.5s ease-in, background-color 0.5s ease-in;
}

.header__phone:hover::after {
  background-color: var(--main-menu-color-white-phone-hover);
}

.header__phone:hover::after {
  width: 100%;
}

.header__сallback:hover {
  text-decoration: underline;
  cursor: pointer;
  color: var(--buttom-background-white-hover);
}

.header__burger {
  display: none;
}

.header__mobile-bar {
  display: none;
}

@media (max-width: 1599.98px) {
  .header__container {
    min-height: 71px;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .header__logo {
    max-width: 200px;
  }

  .header__navigation {
    max-width: 714px;
  }

  .header__contacts {
    max-width: 180px;
  }

  .header__phone {
    font-size: 16px;
  }
}

@media (max-width: 1199.98px) {
  .header__navigation {
    max-width: 606px;
  }
}

@media (max-width: 991.98px) {
  .header__container {
    min-height: 56px;
    padding-top: 8px;
    padding-bottom: 8px;
  }

  .header__navigation {
    display: none;
  }

  .header__contacts {
    display: none;
  }

  .header__burger {
    position: relative;
    z-index: 99999;

    display: block;
  }

  .header__mobile-bar {
    display: block;
  }
}
</style>
