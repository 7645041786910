<template>
    <li :class="classObject" class="card-beforeAfter">
      <ImgComparisonSlider class="card-beforeAfter__images no-swipe">
        <!-- eslint-disable -->
        <img
          slot="first"
          style="width: 100%"
          :src="content.slide.media.before"
        />
        <img
          slot="second"
          style="width: 100%"
          :src="content.slide.media.after"
        />
      </ImgComparisonSlider>
      <div class="card-beforeAfter__content swipe-zone">
        <h3 class="card-beforeAfter__title" >
          {{ content.slide.title }}
        </h3>
        <div class="card-beforeAfter__description" v-if="content.slide.description">
         {{ content.slide.description.slice(0, numberCharacters) }}
          <span v-if="numberCharacters < content.slide.description.length"
            >...
            <Link
              parent-class="button-link__min card-beforeAfter__button"
              v-on:click="fullReviews()"
              >читать еще</Link
            >
          </span>
        </div>
      </div>
    </li>
  </template>
  
  <script>
  import Link from "@/components/element/LinkApp.vue";
  import { ImgComparisonSlider } from '@img-comparison-slider/vue';
  
  export default {
    components: {
      Link,
      ImgComparisonSlider
    },
    props: ["parentClass", "swiper", "content"],
    data() {
      return {
        classObject: {
          [this.parentClass]: this.parentClass,
          ["swiper-slide"]: this.swiper,
          ["card-beforeAfter"]: true,
        },
        numberCharacters: 50,
      };
    },
    methods: {
      fullReviews() {
        this.numberCharacters = this.content.slide.description.length;
      },
    },
  };
  </script>
  
  <style>
  .card-beforeAfter {
    display: flex;
    flex-direction: column;
    min-height: 323px;
  }

  .card-beforeAfter__images {
    max-height: 309px;
  }

  .card-beforeAfter__content {
    padding-top: 30px;
    width: 100%;
  }

  .card-beforeAfter__title {
    margin-bottom: 10px;

    font-family: var(--title2-fonts-family);
    font-style: normal;
    font-weight: 400;
    font-size: var(--title4-fonts-size);
    line-height: var(--title4-line-height);
    color: var(--title4-color-white);
  }

  .card-beforeAfter__description {
    margin: 0 auto;
    margin-bottom: 70px;

    font-family: var(--subtitle3-fonts-family);
    font-style: normal;
    font-weight: 400;
    font-size: var(--subtitle3-fonts-size);
    line-height: var(--subtitle3-line-height);
    color: var(--subtitle3-color-white);
    text-wrap: balance;
  }
  
  .card-beforeAfter__meta {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .card-beforeAfter__rating {
    display: flex;
    flex-grow: 1;
  }
  
  .card-beforeAfter__rating-elem {
    margin-right: 10px;
  }
  
  .card-beforeAfter__rating-elem:last-child {
    margin-right: 0;
  }
  
  .card-beforeAfter__rating-icon path {
    fill: var(--backgraund-background-bg-rating-no-activ);
  }
  
  .card-beforeAfter__rating-icon_color path {
    fill: var(--backgraund-background-bg-rating-activ);
  }
  
  .card-beforeAfter__date {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 170%;
  }
  
  .card-beforeAfter__text {
    margin-bottom: 25px;
  
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 170%;
  }
  
  .card-beforeAfter__author {
    margin-top: auto;
  
    font-family: var(--title4-fonts-family);
    font-style: normal;
    font-weight: 400;
    font-size: var(--title4-fonts-size);
    line-height: 150%;
    color: var(--title4-color-white);
  }
  
  .card-beforeAfter__button {
    display: inline-block;
  }
  
  @media (max-width: 1599.98px) {
    .card-beforeAfter {
      min-height: 270px;
    }
  
    .card-beforeAfter__rating-elem {
      margin-right: 5px;
    }
  
    .card-beforeAfter__rating-icon {
      width: 15px;
      height: 15px;
    }
  
    .card-beforeAfter__date {
      font-size: 12px;
    }
  
    .card-beforeAfter__text {
      margin-bottom: 20px;
  
      font-size: 12px;
    }
  
    .card-beforeAfter__author {
      font-size: 14px;
    }
  }
  
  @media (max-width: 1199.98px) {
    .card-beforeAfter {
      min-height: 266px;
    }
  
    .card-beforeAfter__meta {
      margin-bottom: 12px;
    }
  
    .card-beforeAfter__rating-elem {
      margin-right: 5px;
    }
  
    .card-beforeAfter__rating-icon {
      display: block;
      width: 13px;
      height: 13px;
    }
  }
  
  @media (max-width: 991.98px) {
    .card-beforeAfter {

    }
  
    .card-beforeAfter__meta {
      margin-bottom: 19px;
    }
  
    .card-beforeAfter__date {
      font-size: 12px;
    }
  
    .card-beforeAfter__text {
      margin-bottom: 20px;
  
      font-size: 14px;
    }
  }
  </style>
  