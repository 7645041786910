<template>
    <section id="beforeAfter" class="beforeAfter">
      <div class="beforeAfter__container container">
        <div
          class="beforeAfter__title"
          v-if="content.title"
          v-html="content.title"
        ></div>
        <div
          class="beforeAfter__description"
          v-if="content.description"
          v-html="content.description"
        ></div>
        <div class="beforeAfter__swiper-wrapper" v-if="content.slider">
          <div class="beforeAfter__swiper swiper">
            <ul class="beforeAfter__list swiper-wrapper">
              <ItemBeforeAfter
                swiper="true"
                v-for="item in content.slider"
                :key="item"
                :content="item"
              />
            </ul>
          </div>
          <div class="beforeAfter__slide-button">
            <ButtonSlidePrev parent-class="beforeAfter__prev" />
            <ButtonSlideNext parent-class="beforeAfter__next" />
          </div>
        </div>
      </div>
    </section>
  </template>
  
  <script>
  import Swiper, { Navigation, Pagination } from "swiper";
  import ItemBeforeAfter from "@/components/block/ItemBeforeAfterApp.vue";
  import ButtonSlideNext from "@/components/element/ButtonSlideNextApp.vue";
  import ButtonSlidePrev from "@/components/element/ButtonSlidePrevApp.vue";
  import "swiper/css";
  import "swiper/css/pagination";
  
  export default {
    components: {
        ItemBeforeAfter,
        ButtonSlidePrev,
        ButtonSlideNext,
    },
    data() {
      return {
        content: this.$store.state.data.contentResponse.before_after,
      };
    },
    mounted() {

    const swiper = new Swiper(".beforeAfter__swiper", {
    modules: [Navigation, Pagination],
    centerInsufficientSlides: true,
    noSwiping: true,
    noSwipingClass: 'no-swipe',
    speed: 500,
    navigation: {
        nextEl: ".beforeAfter__next",
        prevEl: ".beforeAfter__prev",
    },
    breakpoints: {
        0: {
        slidesPerView: 1,
        spaceBetween: 0,
        },
        767: {
        slidesPerView: 2,
        spaceBetween: 20,
        },
        992: {
        slidesPerView: 3,
        spaceBetween: 30,
        },
    },
    });

    const swipeZones = document.querySelectorAll('.swipe-zone');

    swipeZones.forEach((zone) => {
    zone.addEventListener('touchstart', (e) => {
        e.stopPropagation();
        swiper.allowTouchMove = true; // Включаем свайп, когда начинается касание в зоне
    });

    zone.addEventListener('touchend', (e) => {
        e.stopPropagation();
        swiper.allowTouchMove = false; // Отключаем свайп после завершения касания
    });

    zone.addEventListener('touchmove', (e) => {
        e.stopPropagation();
        // Здесь можно добавить логику для обработки свайпа в зоне
    });
    });

      
    },
  };
  </script>
  
  <style>
  .beforeAfter {
    margin-bottom: 170px;
  }

  .beforeAfter__slide-button {
    display: flex;
    justify-content: center;
    margin-top: 30px;
    column-gap: 20px;
  }
  
  .beforeAfter__container {
    display: flex;
    flex-direction: column;
  }
  
  .beforeAfter__title {
    margin-bottom: 10px;
  
    font-family: var(--title2-fonts-family);
    font-style: normal;
    font-weight: 400;
    font-size: var(--title2-fonts-size);
    line-height: var(--title2-line-height);
    text-align: center;
    color: var(--title2-color-white);
  }
  
  .beforeAfter__description {
    display: flex;
    max-width: 500px;
    width: 100%;
    margin-bottom: 30px;
    place-self: center;
    text-align: center;
    justify-content: center;
  
    font-family: var(--subtitle3-fonts-family);
    font-style: normal;
    font-weight: 400;
    font-size: var(--subtitle3-fonts-size);
    line-height: var(--subtitle3-line-height);

    color: var(--subtitle3-color-white);
  }
  
  .beforeAfter__swiper-wrapper {
    position: relative;
  }
  
  .beforeAfter__next,
  .beforeAfter__prev {
    z-index: 1;
  }
  
  
  .beforeAfter__link {
    margin-top: 50px;
    align-self: center;
  
    font-family: var(--subtitle3-fonts-family);
    font-size: 14px;
    color: var(--link-color-white);
  }
  
  .beforeAfter__link:hover {
    text-decoration: none;
  }
  
  @media (max-width: 1599.98px) {
    .beforeAfter {
      margin-bottom: 150px;
    }
  
    .beforeAfter__title {
      font-size: 40px;
      line-height: 120%;
    }
  
    .beforeAfter__description {
      max-width: 450px;
      font-size: 12px;
    }
  

  }
  
  @media (max-width: 1199.98px) {
    .beforeAfter {
      margin-bottom: 130px;
    }
  
    .beforeAfter__title {
      font-size: 32px;
    }
  
    .beforeAfter__description {
      max-width: 364px;
    }

  }
  
  @media (max-width: 991.98px) {
    .beforeAfter {
      margin-bottom: 110px;
    }
  
    .beforeAfter__title {
      margin-bottom: 10px;
  
      font-size: 28px;
    }
  
    .beforeAfter__description {
      font-size: 14px;
    }
  
   
  }
  
  @media (max-width: 767.98px) {
    .beforeAfter {
      margin-bottom: 90px;
    }
  
    .beforeAfter__title {
      font-size: 26px;
    }
  
    .beforeAfter__description {
      max-width: calc(100% - 30px);
      max-width: 364px;
    }
  }

  .button-slide-prev {
    display: flex;
  }

  .button-slide-next {
    display: flex;
  }
  </style>
  